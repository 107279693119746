import React from 'react';

import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import P from '../../components/typography/P';
import H2 from '../../components/typography/H2';
import H3 from '../../components/typography/H3';
import CodeInline from '../../components/CodeInline';
import PageHeading from '../../components/PageHeading';

export default () => (
	<Layout>
		<Seo title="Authorisation" />
		<PageHeading
			title="Authorisation"
			lead="A guide to authorisation requirements for the BrightHR APIs."
		/>
		<P>All BrightHR APIs require your app to authorise using OAuth 2.0 access tokens.</P>
		<P>
			The access token should be provided over HTTPS in every request as an{' '}
			<CodeInline>authorization</CodeInline> header, with the value of{' '}
			<CodeInline>
				bearer {'<'}access_token{'>'}
			</CodeInline>
		</P>
		<H2>Authorisation Types</H2>
		<P>
			Your app can be authorised in different ways, depending on the endpoint you`re
			interacting with:
		</P>
		<ul className="ml-6 list-disc">
			<li className="mb-2">
				<span className="text-lg font-bold">Personal Access Token</span>
				<br />
				For interacting with our APIs as yourself only.
			</li>
			<li className="mb-2">
				<span className="text-lg font-bold">User Authorisation</span>
				<br />
				For interacting with our APIs on behalf of other users, using the{' '}
				<a href="https://oauth.net/2/grant-types/authorization-code/">
					OAuth 2.0 Authorisation Code
				</a>{' '}
				grant with PKCE.
			</li>
			<li className="mb-2 font">
				<span className="text-lg font-bold">App Authorisation</span>
				<br />
				For interacting with our APIs without any user context, using the{' '}
				<a href="https://oauth.net/2/grant-types/client-credentials/">
					OAuth 2.0 Client Credentials
				</a>{' '}
				grant.
			</li>
		</ul>
		<H2>App Authorisation</H2>
		<P>
			This form of authorisation allows an app to make API requests on its own behalf (such as
			creating a company at `POST /v1/company`).
		</P>
		<P>
			Access tokens issued with this method of authorisation do not include any user-context,
			therefore, requesting an endpoint which requires a user context with this token will
			produce a `403 Forbidden` response code. See our API Reference for the endpoints that
			are supported.
		</P>
		<P>
			Apps require explicit approval by BrightHR before this type of authorisation method is
			available. Get in touch with us if you believe you have a requirement to use this.
		</P>
		<H3>Obtaining an App access token</H3>
		<P>
			You can generate an access token by passing your app`s Client ID and Client Secret to
			the token request endpoint detailed below.
		</P>
		<ul className="ml-4 list-disc">
			<li className="mb-2">
				<strong>Sandbox</strong> - https://sandbox-login.brighthr.com/connect/token
			</li>
			<li className="mb-2">
				<strong>Production</strong> - https://login.brighthr.com/connect/token
			</li>
		</ul>
		<P>TODO: [Detail request/response here for client credentials grant]</P>
		<H2>User Authorisation</H2>
		<P>
			This form of authorisation allows an app to make API requests on behalf of a BrightHR
			user. As part of the authentication flow, the user consents to your app accessing and/or
			modifying certain `scopes` of the user’s data. (This consent flow can be skipped for
			highly trusted internal apps).
		</P>
		<P>
			Access tokens issued with this authorisation method contain an identifier for the user
			who authenticated, the access-level (scopes) they have provided to your app, and the
			permissions of the user in their company`s subscription. The access token can optionally
			contain user profile information if the correct scope is requested. See API Scopes for
			more details.
		</P>
		<H3>Obtaining a User access token</H3>
		<P>
			Most interaction with the BrightHR API require authorisation by a user. To get that
			authorisation, your app creates a request to the `authorize` endpoint, passing along a
			list of the scopes for which access permission is sought.
		</P>
		<H2>Personal Access Tokens</H2>
		<p>[TODO] Detail how PATs are long running and specific to the user</p>
		<H2>Scopes</H2>
		<P>
			Scopes are requested by a client app and are a way to segregate access to different
			categories of data.
		</P>
		<H3>OpenId Scopes</H3>
		<P>
			Requesting any of the following OpenId scopes results in the access token containing
			claims about the user.
		</P>
		<ul className="ml-4 list-disc">
			<li className="mb-2">
				<strong>openid</strong> - This scope <u>must be requested</u> to identify the
				current user context. This results in a `sub` claim being included that contains
				their unique identifier.
			</li>
			<li className="mb-2">
				<strong>profile</strong> - Requesting this scope will include the following user
				claims: `given_name`, `family_name`, `job_title`, `picture`, `locale`, `zoneinfo`,
				`company_id`.
			</li>
			<li className="mb-2">
				<strong>email</strong> - Requesting this scope will include the user email address
				as the `email` claim.
			</li>
		</ul>
		<H3>API Scopes</H3>
		<P>
			Requesting any of the below API scopes results in the the user being prompted to consent
			to your app accessing that data on their behalf. Each scope represents a collection of
			available endpoints in our API. See the API reference for which scopes grant access to
			which endpoints.
		</P>
		<ul className="ml-4 list-disc">
			<li className="mb-2">
				<strong>company:read</strong>
			</li>
			<li className="mb-2">
				<strong>employee:read</strong>
			</li>
			<li className="mb-2">
				<strong>employee:write</strong>
			</li>
			<li className="mb-2">
				<strong>subscription:write</strong>
			</li>
			<li className="mb-2">
				<strong>etc...</strong>
			</li>
		</ul>
	</Layout>
);
